// Node Modules
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col,Alert } from 'reactstrap';  // Alert, FormText, 
import { Button, FormGroup, FormFeedback, Input, Label, } from 'reactstrap'; // InputGroup, InputGroupText, Fade
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { Map, Marker, GoogleApiWrapper, InfoWindow, Circle } from 'google-maps-react'; // InfoWindow,
import { InfinitySpin } from 'react-loader-spinner';
import Autocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import axios from 'axios';


import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// import ProviderProfile from '../ProviderProfile/ProviderProfile';
import '../Service/service.scss';

import { LOCAL_BURKE_BASE_API_URL,BURKE_BASE_API_URL, BURKE_GOOGLE_AUTOCOMPLETE_API_KEY, BURKE_GOOGLE_MAP_API_KEY } from '../../constants';
import { LS_CUST_LAT, LS_CUST_LNG, LS_CUST_ADDRESS, LS_CUST_NAME, LS_CUST_EMAIL, LS_CUST_PHONE, LS_JOB_URGENCY, LS_JOB_BUDGET, LS_JOB_DESCRIPTION, LS_UPLOADED_MEDIA } from '../../constants';











function Providers(props) {

  let { serviceID } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isRequestSuccess, setRequestSuccess] = useState(true);
  const [theRequestErrorCode, setRequestErrorCode] = useState(true);

  const [theService, setService] = useState(null);
  const [theProviders, setProviders] = useState([]);
  const [theSelectedProvider, setSelectedProvider] = useState(null);
  const [theRadius, setRadius] = useState(25);
  const [theLimit, setLimit] = useState(25);
  const [theResultsCount, setResultsCount] = useState(0);
  const [theSearchAddress, setSearchAddress] = useState('');
  const [theLat, setLat] = useState('');
  const [theLng, setLng] = useState('');
  const [theMarkers, setMarkers] = useState([]);
  const [theCenter, setCenter] = useState({ lat: 0.0, lng: 0.0 });
  const [theActiveMarker, setActiveMarker] = useState(null);
  const [theActiveProviderIdx, setActiveProviderIdx] = useState(-1);
  const [isShowingInfoWindow, setShowingInfoWindow] = useState(false);
  const [theSelectedPlace, setSelectedPlace] = useState(null);

  const [theProfileMode, setProfileMode] = useState(false);
  const [theProfileModeProviderID, setProfileModeProviderID] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [theReviewFormSubmit, settheReviewFormSubmit] = useState(false);



  const [theFullName, setFullName] = useState('');
  const [theEmail, setEmail] = useState('');
  const [thePhoneNumber, setPhoneNumber] = useState('');
  const [theLatitude, setLatitude] = useState(0.0);
  const [theLngtitude, setLngtitude] = useState(0.0);
  const [theUploadedMedia, setUploadedMedia] = useState('');
  const [theAddress, setAddress] = useState('');
  const [theUrgency, setUrgency] = useState('');
  const [theBudget, setBudget] = useState('');
  const [theJobDescription, setJobDescription] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [dangerMessage, setDangerMessage] = useState('');

  const onMarkerClick = () => { }
  const onInfoWindowClose = () => { }


  // Get stuff from local storage
  useEffect(function () {
    let name = localStorage.getItem(LS_CUST_NAME);
    let email = localStorage.getItem(LS_CUST_EMAIL);
    let phone = localStorage.getItem(LS_CUST_PHONE);
    let lat = localStorage.getItem(LS_CUST_LAT) * 1;
    let lng = localStorage.getItem(LS_CUST_LNG) * 1;
    let address = localStorage.getItem(LS_CUST_ADDRESS);
    let urgency = localStorage.getItem(LS_JOB_URGENCY);
    let budget = localStorage.getItem(LS_JOB_BUDGET) * 1;
    let description = localStorage.getItem(LS_JOB_DESCRIPTION);
    let uploadedMedia = JSON.parse(localStorage.getItem(LS_UPLOADED_MEDIA));

    if (name) setFullName(name);
    if (email) setEmail(email);
    if (phone) setPhoneNumber(phone);
    if (lat) setLatitude(lat);
    if (lng) setLngtitude(lng);
    if (address) setAddress(address);
    if (urgency) setUrgency(urgency);
    if (budget) setBudget(budget);
    if (description) setJobDescription(description);
    if (uploadedMedia) setUploadedMedia(uploadedMedia);

  }, []);


  // Load service details
  useEffect(() => {
    let params = '';
    let url = BURKE_BASE_API_URL + '/categories/' + serviceID;

    setLoading(true);

    axios.get(url, {}).then((response) => {

      setLoading(false);
      // setIsRequestSuccess(true);

      if (response.data.success) {
        console.log(response.data.data);
        setService(response.data.data);
      }

    }).catch(function (error) {
      setLoading(false);
      // setIsRequestSuccess(false);

      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setRequestErrorCode(error.response.status);

      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);

      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  }, []);





  // Get stuff from local storage
  useEffect(function () {
    let lat = localStorage.getItem(LS_CUST_LAT) * 1;
    let lng = localStorage.getItem(LS_CUST_LNG) * 1;
    let address = localStorage.getItem(LS_CUST_ADDRESS);

    if (lat) setLat(lat);
    if (lng) setLng(lng);
    if (address) setSearchAddress(address);


  }, []);





  // Update localstorege When the address changes
  useEffect(function () {
    localStorage.setItem(LS_CUST_ADDRESS, theSearchAddress);
  }, [theSearchAddress]);





  // Update local storage and load providers When an address is selected (i.e. lat/lng is changed)
  useEffect(function () {
    localStorage.setItem(LS_CUST_LAT, theLat);
    localStorage.setItem(LS_CUST_LNG, theLng);
    if (theLat && theLng) {
      __loadProviders();
    }
  }, [theLat, theLng]);





  // When radius or limit cahnges
  useEffect(function () {
    __loadProviders();
  }, [theLimit, theRadius]);



  


  const handleSubmitToAdmin = async (e) => {
    e.preventDefault(); 

    let proceed = true;
    let formData = new FormData();
    let uploadedMediaIDs = theUploadedMedia.length ? theUploadedMedia.map(m => m.media_id) : [];

    if (proceed) {
      try {
        setSubmitting(true);
        // setTimeout(function () {
        //   setSubmitting(false);
        // }, 1000);
        // setSubmitting(true);
        formData.append("budget", theBudget);
        formData.append("job_description", theJobDescription);
        formData.append("full_name", theFullName);
        formData.append("email", theEmail);
        formData.append("phone", thePhoneNumber);
        formData.append("address", theAddress);
        formData.append("lead_urgency_type_id", theUrgency);
        formData.append("lat", theLat);
        formData.append("lng", theLng);
        formData.append("zipcode", 1010);
        formData.append("category_id", serviceID);
        formData.append("provider_id", 0);
        formData.append("provider_type", 'default');

        if (uploadedMediaIDs.length > 0) {
          formData.append("media_id", uploadedMediaIDs);
        }

        const response = await fetch(BURKE_BASE_API_URL + '/leads', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json(); // Assuming response is in JSON format
          if (responseData.success) {
            setSuccessMessage(responseData.message || 'Success');
          } else {
            setDangerMessage(responseData.message || 'Unknown error occurred');
          }
          // setSuccessMessage(responseData.message || 'Success'); // Set the success message
          setTimeout(() => {
            setSubmitting(false);
            settheReviewFormSubmit(true);
          }, 5000);

          // setTheReviewFormSubmit(true);

        } else {
          // settheReviewFormSubmit(true);

          console.error('Failed to submit form data:', response.statusText);
        }
      } catch (error) {
        console.error('Error submitting form data:', error);
      } finally {
        setTimeout(() => {
          setSubmitting(false);
          settheReviewFormSubmit(true);

        }, 5000);

      // setTimeout(function () {
      //   setSubmitting(true);
      // }, 1000);
      //   setSubmitting(false); 
      }
    }
  };


  const __loadProviders = () => {
    let url = BURKE_BASE_API_URL + '/providers/search';

    setLoading(true);
    axios.get(url, {
      params: {
        category_id: serviceID,
        lat: theLat,
        lng: theLng,
        radius: theRadius,
        limit: theLimit
      }
    }).then((response) => {

      setLoading(false);
      setRequestSuccess(true);

      if (response.data.success) {
        setResultsCount(response.data.data.length);

        let providers = response.data.data;
        let markers = [];

        providers.forEach((provider, i) => {

          providers[i].provider_idx = i;

          if (provider.serving_areas.length >= 1) {
            provider.serving_areas.forEach((area, j) => {

              providers[i].serving_areas[j].service_area_idx = j;
              providers[i].serving_areas[j].marker = null;

              const markerComponent = '';

              providers[i].serving_areas[j].marker = markerComponent;

              markers.push({
                provider: providers[i],
                provider_idx: i,
                serving_area_idx: j,
                zip: area.zip_codes,
                lat: area.latitude,
                lng: area.longitude,
                distance: area.distance,
                component: markerComponent
              })
            })
          }
        })

        setProviders(providers);
        setMarkers(markers);

        if (markers.length) {
          setCenter({
            lat: markers[0].lat,
            lng: markers[0].lng
          })
        }
      }

    }).catch(function (error) {

      setLoading(false);
      setRequestSuccess(false);

      // Request made and server responded 
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setRequestErrorCode(error.response.status);

        // The request was made but no response was received
      } else if (error.request) {
        console.log(error.request);

        // Something happened in setting up the request that triggered an Error
      } else {
        console.log('Error', error.message);
      }
    });
  }






  const __handleProfileMode = (e, provider) => {

    setProfileMode(false);

    // Same provider was clicked again? expand map and unselect the provider
    if (theSelectedProvider !== null && provider.provider_id === theSelectedProvider.provider_id) {
      setSelectedProvider(null);

      // Another provider was selected, collapse map and select the provider
    } else {

      // Only using timeout here to force the <ProviderProfile /> to reload itself
      setTimeout(function () {
        setProfileMode(true);
        setSelectedProvider(provider);
        // setLat
        // setLng
      }, 20);
    }

    if (theProfileModeProviderID) {

    }
  }






  const __handlePlaceSelected = (place) => {
    if (typeof place.geometry != 'undefined' && typeof place.geometry.location != 'undefined') {
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      setSearchAddress(place.formatted_address);
    }
  }





  const __scrollProviderIntoView = (idx) => {
    if (idx >= 0) {
      const element = document.getElementById('provider-idx-' + idx);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

    }
  }






  const __handleProviderClicked = (e, provider) => {
    if (provider.serving_areas.length >= 1) {
      setCenter({
        lat: provider.serving_areas[0].latitude,
        lng: provider.serving_areas[0].longitude
      })
    }
    setActiveProviderIdx(provider.provider_idx);
    __scrollProviderIntoView(provider.provider_idx);
    setShowingInfoWindow(false);
  }






  const __handleMarkerClicked = (provider, props, marker) => {
    setActiveMarker(marker);
    setShowingInfoWindow(true);
    setSelectedPlace(props);
    setActiveProviderIdx(provider.provider_idx);
    __scrollProviderIntoView(provider.provider_idx);
  }






  const HEADER = () => {
    return theService !== null && <>
      <Row className='page-header style1 mx-0'>
        <Col md={12} >
          <img src={theService.icon} style={{ width: 50 }} />
          <h1>{theService === null ? 'Loading...' : theService.name}</h1>
          <div className='subheading'>{theService === null ? '...' : theService.description}</div>
        </Col>
      </Row>
    </>
  }







  return <><Container className={`page page-service page-service-${serviceID} bg-gray`} fluid style={{ paddingBottom: 100 }}>
    <HEADER />
    <Container className='mt-4'>




      {
        isLoading && <Row className='justify-content-center'>
          <Col sm="12" md="12" lg="6" className='text-center'>
            <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#0d6efd" /></span>
            <div>Loading Providers</div>
          </Col>
        </Row>
      }




      {
        !isLoading && <>


          {
            /* theProfileMode && <ProviderProfile provider={theSelectedProvider} /> */
          }

          {
            <>
              <div className='dynopanel'>
                <Row className='header'>
                  <Col md="12"><h2>Providers</h2></Col>
                </Row>
                <Row className='body'>
                  <Col md="12" lg="6" className='list'>
                    <Row className='search'>
                      <Col md='6'>
                        <FormGroup>
                          <Label for="location">Your Location</Label>
                          {/* <Input
                          className="pac-target-input"
                          id="location"
                          placeholder="Type here to find your address"
                          autocomplete="off"
                          invalid={false}
                          value={''}
                          onChange={e => {
                            // setFullNameError(false);
                            // setFullName(e.target.value)
                          }} /> */}
                          <Autocomplete
                            options={{ types: [], componentRestrictions: { country: "us" } }}
                            id="address"
                            value={theSearchAddress}
                            autoComplete="off"
                            className="form-control"
                            placeholder='Start typing to lookup your address'
                            onChange={e => {
                              setSearchAddress(e.target.value)
                            }}
                            onPlaceSelected={(place) => __handlePlaceSelected(place)}
                            apiKey={BURKE_GOOGLE_AUTOCOMPLETE_API_KEY}
                          />
                          <FormFeedback>Please enter your full name</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md='3'>
                        <FormGroup>
                          <Label for="radius">
                            Search Radius
                          </Label>
                          <Input
                            id="radius"
                            name="radius"
                            type="select"
                            invalid={false}
                            value={theRadius}
                            onChange={e => {
                              setRadius(e.target.value)
                            }} >
                            <option value="10">10 mi</option>
                            <option value="25">25 mi</option>
                            <option value="50">50 mi</option>
                            <option value="100">100 mi</option>
                            <option value="200">200 mi</option>
                            <option value="500">500 mi</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md='3'>
                        <FormGroup>
                          <Label for="limit">
                            Results
                          </Label>
                          <Input invalid={false} value={theLimit} onChange={e => {
                            setLimit(e.target.value)
                          }} id="limit" name="limit" type="select">
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className='total'>
                      <Col md='12'>
                        <h3>
                          <strong id="countResults">{theResultsCount} Results</strong> around
                          <img alt="Proficiency Demonstration" className="icon-marker-base" src="http://sabademoproject.com/wp-content/themes/wexim-child/saba/assets/images/marker-default.png" />
                          <span className="current-base-location" id="currentBaseLocation">{theSearchAddress}</span>
                        </h3>
                      </Col>
                    </Row>
                   

              {/* <Row className='total'>
                <Col md='12'>
                  {theReviewFormSubmit && (
                    successMessage ? (
                      <Alert color="success" style={{ marginRight: '2px' }}>
                        <FontAwesomeIcon icon={faCircleCheck} /> {successMessage}.
                      </Alert>
                    ) : (
                      <Alert color="danger" style={{ marginRight: '2px' }}>
                        <FontAwesomeIcon icon={faExclamationCircle} /> {dangerMessage}.
                      </Alert>
                    )
                  )}
                  {theResultsCount === 0 ? (
                    !theReviewFormSubmit && (
                      <h3 style={{ textAlign: "left", marginLeft: "16px" }}> 
                        <Button className='mb-2 w-100' color="primary" size="medium" onClick={handleSubmitToAdmin} style={{ height: 50 }} disabled={isSubmitting}>
                          {isSubmitting ? (
                            <span style={{ marginLeft: "-50px", display: "inline-block" }}>
                              <InfinitySpin ariaLabel="loading" width='80' color="#fff" />
                            </span>
                          ) : (
                            <>Submit To Admin</>
                          )}
                        </Button>
                      </h3>
                    )
                  ) : (
                    <h3>
                      <strong id="countResults">{theResultsCount} Results</strong> around
                      <img alt="Proficiency Demonstration" className="icon-marker-base" src="http://sabademoproject.com/wp-content/themes/wexim-child/saba/assets/images/marker-default.png" />
                      <span className="current-base-location" id="currentBaseLocation">{theSearchAddress}</span>
                    </h3>
                  )}
                </Col>
              </Row> */}
                
                    <Row className=''>
                      <Col md='12'>
                        <div className="entries scroll scroll-5">
                          <div className='entries-placeholder'>
                            {
                              
                              theProviders.map((provider, i) => {
                                let activeProvider = theActiveProviderIdx === provider.provider_idx;
                                return <>
                                  {/* <Link exact to={`/services/${serviceID}/providers/${provider.provider_id}`} className="nav-link" activeClassName="active">
                                </Link> */}
                                  {/* <a onClick={e => __handleProfileMode(e, provider)} > */}
                                  <a id={"provider-idx-" + provider.provider_idx} onClick={e => __handleProviderClicked(e, provider)} >
                                    <Row className={"entry entry-123 mt-3" + (activeProvider ? ' selected ' : '')}>
                                      <Col md='10' className='info'>
                                        <div className="col col-md-10 info">
                                          <h4 className="name"><strong>{provider.business}</strong><div className="distance">{provider.serving_areas[0].distance} mi</div></h4>
                                          {provider.full_address && <div className="address"><FontAwesomeIcon icon={faMapMarker} /> {provider.full_address}</div>}
                                          {/*
                                          <a className="phone" href="tel:"><FontAwesomeIcon icon={faPhone} /> {provider.phone} </a>
                                          <a className="email" href={"mailto:" + provider.email}><FontAwesomeIcon icon={faEnvelope} /> {provider.email}</a>
                                          */}
                                          <div className="directions mt-2">
                                            <Link exact to={`/services/${serviceID}/providers/${provider.slug}`} className="nav-link" activeClassName="active">
                                              <Button size="sm" color="primary">View Profile</Button> {provider.serving_areas.length >= 1 && <span>Serving {provider.serving_areas.length} locations</span>}
                                            </Link>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md='2' className='pic'>
                                        {
                                          provider.logo_url && <img src={provider.logo_url} />
                                        }
                                      </Col>
                                    </Row></a>
                                </>
                              })
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md='6' className='map shorter no-transition-at-all'>

                    <Map google={props.google} zoom={12} disableDefaultUI={false}
                      containerStyle={{
                        position: 'relative',
                        height: '100%', // (theProfileMode ? '300px' : '100%')
                        width: '100%'
                      }}

                      center={{
                        lat: theCenter.lat,
                        lng: theCenter.lng
                      }}
                      initialCenter={{
                        lat: theLat,
                        lng: theLng
                      }}>

                      {
                        theMarkers.map((theMarker, i) => {

                          // Only show the locations of the selected provider.
                          // Or show all providers' locations if none is selected
                          let $allow = true;
                          if (theActiveProviderIdx >= 0) {
                            if (theActiveProviderIdx === theMarker.provider_idx) {
                              $allow = true;
                            } else {
                              $allow = false;
                            }
                          }

                          return $allow && <Marker
                            onClick={(props, marker) => { __handleMarkerClicked(theMarker.provider, props, marker); }}
                            name={theMarker.zip}
                            position={{ lat: theMarker.lat, lng: theMarker.lng }}
                            icon={{
                              url: (theActiveProviderIdx === theMarker.provider_idx ? "/assets/marker-active.png" : "/assets/marker.png"),
                              anchor: new window.google.maps.Point(32, 32),
                              scaledSize: new window.google.maps.Size(64, 64)
                            }}
                          />

                        })
                      }

                      <InfoWindow
                        marker={theActiveMarker}
                        onClose={() => { setActiveProviderIdx(-1); setShowingInfoWindow(!isShowingInfoWindow) }}
                        visible={isShowingInfoWindow}
                      >
                        <div className="info-window">
                          {isShowingInfoWindow && theActiveProviderIdx >= 0 && <>
                            <h4>{theProviders[theActiveProviderIdx].business}</h4>
                            {theProviders[theActiveProviderIdx].logo_url && <img className='logo' src={theProviders[theActiveProviderIdx].logo_url} />}
                            {theProviders[theActiveProviderIdx].full_address && <div className="address mt-2"><FontAwesomeIcon icon={faMapMarker} /> {theProviders[theActiveProviderIdx].full_address}</div>}
                            <div className='mt-2'><a className="phone" href="tel:"><FontAwesomeIcon icon={faPhone} /> {theProviders[theActiveProviderIdx].phone} </a></div>
                            <div className='mt-2'><a className="email" href={"mailto:" + theProviders[theActiveProviderIdx].email}><FontAwesomeIcon icon={faEnvelope} /> {theProviders[theActiveProviderIdx].email}</a></div>
                            <div className='mt-2'>
                              <a href={`/services/${serviceID}/providers/${theActiveProviderIdx >= 0 && theProviders[theActiveProviderIdx].provider_id}`} className="nav-link">
                                <Button size="sm" color="primary">View Profile</Button>
                              </a>
                            </div>
                          </>}
                        </div>
                      </InfoWindow>

                      <Circle
                        radius={theRadius * 1609.344} // Meters from Miles
                        center={{ lat: theLat, lng: theLng }}
                        strokeColor='maroon'
                        strokeOpacity={0.5}
                        strokeWeight={2}
                        fillColor='#ff0000'
                        fillOpacity={0.1}
                      />
                    </Map>
                  </Col>
                </Row>
              </div>
              {/* <div className="text-center mt-4"><a className='href-back' href={() => false} onClick={() => setCurrentStep(4)}>Back</a></div> */}
            </>
          }

        </>
      }

    </Container>
  </Container>
  </>
}







export default GoogleApiWrapper({
  apiKey: BURKE_GOOGLE_MAP_API_KEY
})(Providers)
