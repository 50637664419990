// Node Modules
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Container, Row, Col, Alert } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faCircleCheck } from '@fortawesome/free-solid-svg-icons';

// Project Components
// import { SettingsContext } from '../../App';
import Loader from '../../Components/Loader';
import RequestFailed from '../../Components/RequestFailed';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

// Project Constants
import {
  LS_CUST_LAT, LS_CUST_LNG, LS_CUST_ADDRESS, LS_CUST_NAME, LS_CUST_EMAIL, LS_CUST_PHONE, LS_UPLOADED_MEDIA,
  LS_JOB_URGENCY, LS_JOB_BUDGET, LS_JOB_DESCRIPTION,
  BURKE_BASE_API_URL, LOCAL_BURKE_BASE_API_URL
} from '../../constants';











function Service() {
  // const settings = useContext(SettingsContext);
  let { serviceID } = useParams();

  // States
  const [theCurrentStep, setCurrentStep] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(true);
  const [theRequestErrorCode, setRequestErrorCode] = useState(0);
  const [theService, setService] = useState({
    name: 'Architecture',
    description: 'In publishing and graphic design, Lorem ipsum is a placeholder text.',
    icon: 'https://burke.sabademoproject.com/storage/app/public/154/archway.png'
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoadingProviders, setLoadingProviders] = useState(true);

  const [theSearchAddress, setSearchAddress] = useState('');
  const [theLat, setLat] = useState(0.0);
  const [theLng, setLng] = useState(0.0);
  const [theUrgency, setUrgency] = useState('');
  const [theBudget, setBudget] = useState('');
  const [theJobDescription, setJobDescription] = useState('');
  const [theFullName, setFullName] = useState('');
  const [theEmail, setEmail] = useState('');
  const [thePhoneNumber, setPhoneNumber] = useState('');
  const [theUploadedMedia, setUploadedMedia] = useState('');

  const [theSearchAddressError, setSearchAddressError] = useState(false);
  const [theUrgencyError, setUrgencyError] = useState(false);
  const [theBudgetError, setBudgetError] = useState(false);
  const [theJobDescriptionError, setJobDescriptionError] = useState(false);
  const [theFileCountError, setFileCountError] = useState(false);
  const [theFileSizeError, setFileSizeError] = useState(false);
  const [theFullNameError, setFullNameError] = useState(false);
  const [theEmailError, setEmailError] = useState(false);
  const [thePhoneNumberError, setPhoneNumberError] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);




  // Load service details
  useEffect(() => {
    let params = '';
    let url = BURKE_BASE_API_URL + '/categories/' + serviceID + (params ? '?' + params : '');

    setLoading(true);

    axios.get(url, {}).then((response) => {

      setLoading(false);
      setIsRequestSuccess(true);

      if (response.data.success) {
        console.log(response.data.data);
        setService(response.data.data);
      }

    }).catch(function (error) {
      setLoading(false);
      setIsRequestSuccess(false);

      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setRequestErrorCode(error.response.status);

      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);

      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  }, []);

  
  
  // Get stuff from local storage
  useEffect(function () {
    let name = localStorage.getItem(LS_CUST_NAME);
    let email = localStorage.getItem(LS_CUST_EMAIL);
    let phone = localStorage.getItem(LS_CUST_PHONE);
    let lat = localStorage.getItem(LS_CUST_LAT) * 1;
    let lng = localStorage.getItem(LS_CUST_LNG) * 1;
    let address = localStorage.getItem(LS_CUST_ADDRESS);
    let urgency = localStorage.getItem(LS_JOB_URGENCY);
    let budget = localStorage.getItem(LS_JOB_BUDGET) * 1;
    let description = localStorage.getItem(LS_JOB_DESCRIPTION);
    let uploadedMedia = JSON.parse(localStorage.getItem(LS_UPLOADED_MEDIA));
    console.log('uploadedMedia', uploadedMedia);

    if (name) setFullName(name);
    if (email) setEmail(email);
    if (phone) setPhoneNumber(phone);
    if (lat) setLat(lat);
    if (lng) setLng(lng);
    if (address) setSearchAddress(address);
    if (urgency) setUrgency(urgency);
    if (budget) setBudget(budget);
    if (description) setJobDescription(description);
    if (uploadedMedia) setUploadedMedia(uploadedMedia);

  }, []);

  // Update stuff to local storage
  useEffect(function () {
    localStorage.setItem(LS_CUST_NAME, theFullName);
    localStorage.setItem(LS_CUST_EMAIL, theEmail);
    localStorage.setItem(LS_CUST_PHONE, thePhoneNumber);
    localStorage.setItem(LS_CUST_LAT, theLat);
    localStorage.setItem(LS_CUST_LNG, theLng);
    localStorage.setItem(LS_CUST_ADDRESS, theSearchAddress);
    localStorage.setItem(LS_JOB_URGENCY, theUrgency);
    localStorage.setItem(LS_JOB_BUDGET, theBudget);
    localStorage.setItem(LS_JOB_DESCRIPTION, theJobDescription);
    localStorage.setItem(LS_UPLOADED_MEDIA, JSON.stringify(theUploadedMedia));
  }, [theFullName, theEmail, thePhoneNumber, theLat, theLng, theSearchAddress, theUrgency, theBudget, theJobDescription, theUploadedMedia]);

  
  
  
  
  
  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <Col md={12} >
          <img src={theService.icon} style={{ width: 50 }} />
          <h1>{theService === null ? 'Loading...' : theService.name}</h1>
          <div className='subheading'>{theService === null ? '...' : theService.description}</div>
        </Col>
      </Row>
    </>
  }

  // const SERVICE_NOT_FOUND = () => {
  //   return <Alert color="danger">This service was found</Alert>
  // }

  
  
  
  
  
  const __handlePlaceSelected = (place) => {
    console.log(place);
    if (typeof place.geometry != 'undefined' && typeof place.geometry.location != 'undefined') {
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      setSearchAddress(place.formatted_address);
    }
  }
  
  
  
  const __handleSubmitStep1 = (e) => {
    if (theLat === 0.0) {
      setSearchAddressError(true);
      return;
    }
    setSubmitting(true);
    setTimeout(function () {
      setSubmitting(false);
      setCurrentStep(2);
    }, 1000);
  }

  
  
  
  
  
  const __handleSubmitStep2 = (e) => {

    let proceed = true;

    if(!theUrgency) {
      setUrgencyError(true);
      proceed = false;
    }

    if(!theBudget) {
      setBudgetError(true);
      proceed = false;
    }

    if(!theJobDescription) {
      setJobDescriptionError(true);
      proceed = false;
    }

    if(proceed) {
      setSubmitting(true);
      setTimeout(function () {
        setSubmitting(false);
        setCurrentStep(3);
      }, 1000);
    }
  }

  
  
  
  
  
  const __handleSubmitStep3 = (e) => {
    setSubmitting(true);
    setTimeout(function () {
      setSubmitting(false);
      setCurrentStep(4);
    }, 1000);
  }

  
  
    // optional Submit Provider 
    const __handleSubmitStep4 = async (e) => {
      e.preventDefault();

      let proceed = true;
      let uploadedMediaIDs = theUploadedMedia.length ? theUploadedMedia.map(m => m.media_id) : [];

      // Check if required fields are filled
      if (!theFullName) {
        setFullNameError(true);
        proceed = false;
      }

      if (!theEmail) {
        setEmailError(true);
        proceed = false;
      }

      if (!thePhoneNumber) {
        setPhoneNumberError(true);
        proceed = false;
      }

      if (proceed) {
        try {
          setSubmitting(true);

          // Create FormData object
          const formData = new FormData();
          formData.append('budget', theBudget);
          formData.append('job_description', theJobDescription);
          formData.append('full_name', theFullName);
          formData.append('email', theEmail);
          formData.append('phone', thePhoneNumber);
          formData.append('address', theSearchAddress);
          formData.append('lead_urgency_type_id', theUrgency);
          formData.append('lat', theLat);
          formData.append('lng', theLng);
          formData.append('zipcode', 1010);
          formData.append('category_id', serviceID);
          formData.append('provider_id', 350);
          formData.append('media_id', uploadedMediaIDs);

          // Make POST request
          const response = await fetch(BURKE_BASE_API_URL + '/leads', {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            setSearchAddress('');
            setLat('');
            setLng('');
            setUrgency('');
            setBudget('');
            setJobDescription('');
            setFullName('');
            setEmail('');
            setPhoneNumber('');
            setUploadedMedia([]);
            setShowSuccessMessage(true);
          } else {
            console.error('Failed to submit form data:', response.statusText);
          }
        } catch (error) {
          console.error('Error submitting form data:', error);
        } finally {
          setSubmitting(false);
        }
      }
    };


  return <>
    <Container className={`page page-service page-service-${serviceID} bg-gray`} fluid style={{ paddingBottom: 100 }}>
      <HEADER />
      <Container>
        <Row className={`pt-8`}>
          <Col md="12">
            {
              isLoading ? <Loader /> : (!isRequestSuccess ? <RequestFailed errorCode={theRequestErrorCode} /> : <></>)
            }
            {
              !isLoading && <>

                {/* STEP 1 */}

                {
                  theCurrentStep === 1 && <Step1
                    theSearchAddress={theSearchAddress}
                    setSearchAddress={setSearchAddress}
                    theSearchAddressError={theSearchAddressError}
                    setSearchAddressError={setSearchAddressError}
                    isSubmitting={isSubmitting}
                    __handlePlaceSelected={__handlePlaceSelected}
                    __handleSubmitStep1={__handleSubmitStep1}
                  />
                }

                {/* STEP 2 */}

                {
                  theCurrentStep === 2 && <Step2
                    theUrgency={theUrgency}
                    setUrgency={setUrgency}
                    theUrgencyError={theUrgencyError}
                    setUrgencyError={setUrgencyError}
                    theBudget={theBudget}
                    setBudget={setBudget}
                    theBudgetError={theBudgetError}
                    setBudgetError={setBudgetError}
                    theJobDescription={theJobDescription}
                    setJobDescription={setJobDescription}
                    theJobDescriptionError={theJobDescriptionError}
                    setJobDescriptionError={setJobDescriptionError}
                    setCurrentStep={setCurrentStep}
                    __handleSubmitStep2={__handleSubmitStep2}
                    isSubmitting={isSubmitting}
                  />
                }

                {/* STEP 3 */}

                {
                  theCurrentStep === 3 && <Step3
                    theFileCountError={theFileCountError}
                    theFileSizeError={theFileSizeError}
                    __handleSubmitStep3={__handleSubmitStep3}
                    isSubmitting={isSubmitting}
                    setSubmitting={setSubmitting}
                    setCurrentStep={setCurrentStep}
                    theUploadedMedia={theUploadedMedia}
                    setUploadedMedia={setUploadedMedia}
                  />
                }

                {/* STEP 4 */}

               {/* { showSuccessMessage && (
                        <Alert color="success">
                          <FontAwesomeIcon icon={faCircleCheck} /> Email Sent to Default Provider.
                        </Alert>
                )}  */}

                {
                  theCurrentStep === 4 && <Step4
                    theFullName={theFullName}
                    setFullName={setFullName}
                    theFullNameError={theFullNameError}
                    setFullNameError={setFullNameError}
                    theEmail={theEmail}
                    setEmail={setEmail}
                    theEmailError={theEmailError}
                    setEmailError={setEmailError}
                    thePhoneNumber={thePhoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    thePhoneNumberError={thePhoneNumberError}
                    setPhoneNumberError={setPhoneNumberError}
                    setCurrentStep={setCurrentStep}
                    isSubmitting={isSubmitting}
                    __handleSubmitStep4={__handleSubmitStep4}
                    showSuccessMessage={showSuccessMessage}

                  />
                }

                
                {/* STEP 5 */}

                {
                  theCurrentStep === 5 && <Navigate to={`/services/${serviceID}/providers`} />
                  
                  /*
                  <>
                    {
                      !isLoadingProviders && <>
                        <Providers
                          theSearchAddress={theSearchAddress}
                          setSearchAddress={setSearchAddress}
                          theLat={theLat} theLng={theLng}
                          setLat={setLat} setLng={setLng}
                          __handlePlaceSelected={__handlePlaceSelected}
                          setCurrentStep={setCurrentStep}
                        />
                      </>
                    }
                  </>
                  */
                 
                }

              </>
            }
          </Col>
        </Row>

      </Container>
    </Container>
  </>
}

export default Service;